import React, {useState, useEffect} from "react";
import IntroContent from "../solutions/IntroContent";
const EventBanner = ({entity, locale, selectedEventType, translatedEventsTab}) => {
  const [translatedEventBanner, setEventBanner] = useState('');
  useEffect(() => {

     let bannerEventType = (translatedEventsTab.find(x => x?.relationships?.eventType?.name.toUpperCase() === selectedEventType))
    
     setEventBanner(bannerEventType?.relationships?.fieldBanner?.relationships?.fieldIntroContentItem[0])
  },[])
  return(
    <div>
      {translatedEventBanner && <IntroContent type="l" contentL = {translatedEventBanner}/>}
    </div>
  )
}
export default EventBanner;