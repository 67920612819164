import { useStaticQuery, graphql } from "gatsby";

// This function returns New Homepage data created on drupal.
export default function useEventsWebinarData(locale = 'en') {
  const data = useStaticQuery(graphql`
  {
    allTaxonomyTermEventType(sort: {fields: weight, order: ASC}) {
      nodes {
        uuid: drupal_id
        entityLabel: name
      }
    }
    eventsData:  allNodeInsightsEvent(filter: {field_hide_from_event_overview_p: {eq: false}, status: {eq: true}}) {
      nodes {
        uuid: drupal_id
        relationships {
          eventType: event_type {
            name
          }
          fieldMediaImage: field_media_image {
            fieldImage: field_image {
              alt
            }
            relationships {
              fieldImage: field_image {
                gatsbyImage(width: 600, height: 420, layout: CONSTRAINED, placeholder:DOMINANT_COLOR)
              }
            }
          }
        }
        promote
        entityLabel: title
        entityId: id
        langcode
        entityUrl: path {
          path: alias
        }
        fieldVirtualEvent: field_virtual_event
        fieldTimeZone: field_time_zone
        fieldStartEndTimes: field_start_end_times {
          endDate: end_value
          startDate: value
          value
        }
        startEndTimes: start_end_times {
          endDate: end_value
          startDate: value
          value
        }
        fieldResourceDescription: field_resource_description
        fieldOverviewPageButtonText: field_overview_page_button_text
        fieldExternalLink: field_external_link {
          title
          uri
        }
      }
    } 
    webinarsData:  allNodeWebinar(
      filter: {field_hide_page: {eq: false}, field_webinar_type: {eq: "future"}, status: {eq: true}}
    ) {
      nodes {
        uuid: drupal_id
        relationships {
          fieldMediaImage: field_media_image {
            fieldImage: field_image {
              alt
            }
            relationships {
              fieldImage: field_image {
                gatsbyImage(width: 600, height: 420, layout: CONSTRAINED, placeholder:DOMINANT_COLOR)
              }
            }
          }
        }
        __typename
        type: field_webinar_type
        promote
        entityLabel: title
        entityId: id
        langcode
        entityUrl: path {
          path: alias
        }
        fieldTimeZone: field_time_zone
        fieldResourceDescription: field_resource_description
        fieldOverviewPageButtonText: field_overview_page_button_text
        fieldExternalLink: field_external_link {
          title
          uri
        }
        fieldStartEndTimes: field_start_end_times {
          endDate: end_value
          startDate: value
          value
        }
        startEndTimes: start_end_times {
          endDate: end_value
          startDate: value
          value
        }
      }
    }
  }
`);
  return { fetchedData: data };
}
